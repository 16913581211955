import React, { useState, useEffect } from "react";
import SK_logo_white from "../../../utils/images/SKsta_30light-gray.png";
import cameraCertLogo from "../../../utils/images/kamera.jpg";
import brakeInCertLogo from "../../../utils/images/inbrott.jpg";
import { motion } from "framer-motion";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase";

const Certify = () => {
  const [context, setContext] = useState([]);
  const path = "context/hpKSVqJ0vT1knr3H6XLQ/certify";
  useEffect(() => {
    const customerColletctionRef = collection(db, path);
    const unsub = onSnapshot(customerColletctionRef, (snapShot) => {
      let list = [];
      snapShot.docs.forEach((doc) => {
        list.push({ ...doc.data(), id: doc.id });
      });

      setContext(list);
    });
    return () => {
      unsub();
    };
  }, []);

  return (
    <div>
      <motion.div
        className="z-i-3"
        initial={{
          width: "100vw",
          height: "100vh",
          bottom: "0vh",
          position: "absolute",
          opacity: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgb(204 204 204)",
        }}
        transition={{ duration: 2 }}
        animate={{
          bottom: ["0vh", "0vh", "0vh", "100vh"],
          opacity: [1, 1, 1, 0],
        }}
      >
        <h2>CERTIFIKAT</h2>
      </motion.div>
      <div className="d-flex justify-content-center services-hero relative">
        <div className="dark-filter absolute col-12 z-i-2" />
        <div className="faded-logo">
          <img src={SK_logo_white} className="w-100" alt="SK logo faded" />
        </div>
        <div className="col-12 d-flex justify-content-center align-items-center flex-column color-white z-i-2">
          <div className="xs-col xs-align-center md-row md-align-start">
            <h1 className="u-fs-uppercase mb-0">Certifikat</h1>
          </div>
        </div>
      </div>
      {context.length > 0 && (
        <div className="section">
          <div className="section-container d-flex justify-content-center xs-col md-row">
            <div className="flex-column xs-col-12 md-col-6">
              <div className="pt-24 pb-24 px-16 d-flex xs-col md-row">
                <div className="xs-col-12 md-col-6 d-flex justify-content-end xs-pl-0 lg-pl-8">
                  <img
                    src={brakeInCertLogo}
                    className="col-12"
                    alt="SK logo faded"
                  />
                </div>
                <div className="xs-col-12 md-col-6 xs-pl-0 lg-pl-8">
                  <img
                    src={cameraCertLogo}
                    className="col-12"
                    alt="SK logo faded"
                  />
                </div>
              </div>
              <div className="flex-column xs-col sm-row justify-content-center">
                <div className="pt-24 pb-24  px-16">
                  <p>{context[0].value}</p>
                  <p>{context[1].value}</p>
                  <p>{context[2].value}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Certify;
