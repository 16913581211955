import React, { useState, useEffect } from "react";
import Scroll from "react-scroll-to-element";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase";

import SK_logo from "../../../utils/images/SKsta_30_black.png";
import SK_logo_white from "../../../utils/images/SKsta_30light-gray.png";

import { motion } from "framer-motion";

import "../../../../src/styles/helper.scss";

function ContentContainer() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [context, setContext] = useState([]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const path = "context/wzWaPFRgO4rdpDRSYKlc/startpage";
  useEffect(() => {
    const customerColletctionRef = collection(db, path);
    const unsub = onSnapshot(customerColletctionRef, (snapShot) => {
      let list = [];
      snapShot.docs.forEach((doc) => {
        list.push({ ...doc.data(), id: doc.id });
      });

      setContext(list);
    });
    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="container-wrapper">
      <motion.div
        className="z-i-3"
        initial={{
          width: "100vw",
          height: "100vh",
          bottom: "0vh",
          position: "absolute",
          opacity: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgb(204 204 204)",
        }}
        transition={{ duration: 2 }}
        animate={{
          bottom: ["0vh", "0vh", "0vh", "100vh"],
          opacity: [1, 1, 1, 0],
        }}
      >
        <h2>STARTSIDA</h2>
      </motion.div>
      <section className="d-flex justify-content-center hero-image relative z-i-2">
        <div className="dark-filter absolute col-12 z-i-1" />
        <div
          className="faded-logo"
          style={{ bottom: `${scrollPosition / 3}px` }}
        >
          <img src={SK_logo_white} className="w-100" alt="SK logo faded" />
        </div>
        <Scroll type="id" element="One" offset={-50}>
          <div className="z-i-3">
            <div className="icon-scroll"></div>
          </div>
        </Scroll>
        <div className="col-12 d-flex justify-content-center align-items-center flex-column color-white z-i-2">
          <img src={SK_logo} alt="" />
          <div className="xs-col xs-align-center md-row md-align-start">
            <h1 className="u-fs-uppercase mb-0">Säkerhets</h1>
            <h1 className=" u-fs-uppercase u-mb-0">konsulterna</h1>
          </div>
          <div className="xs-col xs-align-center md-row md-align-start">
            <h1 className="u-fs-uppercase mb-0">Sverige AB</h1>
          </div>
        </div>
      </section>
      {context.length > 0 && (
        <div>
          <div id="One" className="section">
            <div className="section-container">
              <h2>{context[1].value}</h2>
              <p>{context[3].value}</p>
              <p>{context[2].value}</p>
            </div>
          </div>
          <div className="section light-gray">
            <div className="section-container">
              <h4>{context[0].value}</h4>
              <p>{context[5].value}</p>
            </div>
          </div>
          <div className="section">
            <div className="section-container">
              <p>{context[4].value}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContentContainer;
