// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore } from '@firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyA0SJuVycpu1_e5nGUAxT0tsL1vtEUH-8s",
  authDomain: "sk-admin-db.firebaseapp.com",
  databaseURL: "https://sk-admin-db-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "sk-admin-db",
  storageBucket: "sk-admin-db.appspot.com",
  messagingSenderId: "411201575489",
  appId: "1:411201575489:web:90df398d29c6c792c484c0",
  measurementId: "G-EST223DFGB"
};

const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const storage = getStorage(app)
export const auth = getAuth(app)

