import React, { useState, useEffect, Fragment } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

import SK_logo from "../../utils/images/SKsta_30_black.png";

function Header() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [state, setState] = useState({
    left: false,
  });

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div
        className="px-16 py-16 d-flex flex-column justify-content-between"
        style={{ height: "100vh" }}
      >
        <div>
          <Link className="header-link" to="/">
            <div>Startsida</div>
          </Link>
          <Link className="header-link" to="tjanster">
            <div>Tjänster</div>
          </Link>
          <Link className="header-link" to="certifikat">
            <div>Certifikat</div>
          </Link>
          <Link className="header-link" to="bli-var-kollega">
            <div>Bli vår kollega</div>
          </Link>
          {/* <div>Om oss</div> */}
          <Link className="header-link" to="kontakta-oss">
            <div>Kontakta oss</div>
          </Link>
        </div>
        <div style={{ textAlign: "center" }}>
          <img src={SK_logo} alt="" style={{ width: "50px", opacity: 0.4 }} />
        </div>
      </div>
    </Box>
  );

  return (
    <div
      className={
        scrollPosition > 30
          ? "header-wrapper d-flex align-items-center justify-content-between z-i-4 fadeInHeader"
          : "header-wrapper d-flex align-items-center justify-content-between z-i-4 fadeOutHeader"
      }
    >
      <div className="px-16 col-5 d-flex align-items-center">
        <MenuIcon sx={{ fontSize: 40 }} onClick={toggleDrawer("left", true)} />
        <p className="mb-0">Meny</p>
      </div>
      <div className="menu-container col-2">
        <Link to="/">
          {/* <i className="icon-lock_outline"/> */}
          <img src={SK_logo} alt="" />
        </Link>
      </div>
      <div className="col-5 d-flex justify-content-end px-16">
        <Link className="header-link" to="kontakta-oss">
          <p className="mb-0">Kontakta oss</p>
        </Link>
      </div>
      {["left", "right", "top", "bottom"].map((anchor) => (
        <Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </Fragment>
      ))}
      {/* <div className="px-8 py-8 d-flex align-items-center">
                <div className="px-8 menu_item">
                    <Scroll type="id" element="services">Tjänster</Scroll>
                </div>
                <div className="px-8 menu_item">
                    <Scroll type="id" element="about-us">Om oss</Scroll>
                </div>
                <div className="px-8 menu_item">
                    <Scroll type="id" element="contact-us">Kontakt</Scroll>
                </div>
            </div> */}
    </div>
  );
}

export default Header;
