import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ContentContainer from "../../components/pages/container/container";
import Services from "../../components/pages/services/services";
import ContactUs from "../../components/pages/contact-us/contact-us";
import JoinUs from "../../components/pages/join-us/join-us";
import Certify from "../pages/certify/certify";

import { AnimatePresence } from "framer-motion";

const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<ContentContainer />} />
        <Route path="tjanster" element={<Services />} />
        <Route path="certifikat" element={<Certify />} />
        <Route path="kontakta-oss" element={<ContactUs />} />
        <Route path="bli-var-kollega" element={<JoinUs />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
