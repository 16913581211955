import './App.scss';
import React from 'react'
import { BrowserRouter } from "react-router-dom";
import Header from './components/header/header';
import Footer from './components/footer/footer'
import AnimatedRoutes from './components/animated-routes/animated-routes';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <AnimatedRoutes />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
