import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import SK_logo_white from "../../../utils/images/SKsta_30light-gray.png";

const Services = () => {
  const [selectedSection, setSelectedSection] = useState("Säkerhetsanalys");

  const listOfServices = [
    "Säkerhetsanalys",
    "Utredning",
    "Projektledning",
    "Upphandling",
    "Säkerhetsstrategier",
    "Inventering",
    "Granskning",
    "Dokumentation",
    "Besiktning",
    "Anbudsutvärdering",
    "Second opinion",
    "Rådgivning",
  ];

  return (
    <div className="container-wrapper">
      <motion.div
        className="z-i-3"
        initial={{
          width: "100vw",
          height: "100vh",
          bottom: "0vh",
          position: "absolute",
          opacity: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgb(204 204 204)",
        }}
        transition={{ duration: 2 }}
        animate={{
          bottom: ["0vh", "0vh", "0vh", "100vh"],
          opacity: [1, 1, 1, 0],
        }}
      >
        <h2>TJÄNSTER</h2>
      </motion.div>
      <div>
        <div className="d-flex justify-content-center services-hero relative">
          <div className="dark-filter absolute col-12 z-i-2" />
          <div className="faded-logo">
            <img src={SK_logo_white} className="w-100" alt="SK logo faded" />
          </div>
          <div className="col-12 d-flex justify-content-center align-items-center flex-column color-white z-i-2">
            <div className="xs-col xs-align-center md-row md-align-start">
              <h1 className="u-fs-uppercase mb-0">Tjänster</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 section">
        <div className="section-container xs-col md-row ">
          <div className="md-d-none mb-16">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Välj tjänst
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedSection}
                  label={selectedSection}
                  onChange={(e) => setSelectedSection(e.target.value)}
                >
                  {listOfServices.map((service, index) => (
                    <MenuItem value={service}>{service}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="col-3 xs-d-none md-d-initial">
            {listOfServices.map((service, index) => (
              <p
                className="mb-0 d-flex "
                onClick={() => setSelectedSection(service)}
                style={
                  selectedSection === service
                    ? { fontWeight: "bolder", cursor: "pointer" }
                    : { fontWeight: "normal", cursor: "pointer" }
                }
                key={index}
              >
                {service}
              </p>
            ))}
          </div>
          <div className="xs-col-12 md-col-9">
            {selectedSection === "Säkerhetsanalys" && (
              <div className="relative">
                <div
                  className={
                    selectedSection === "Säkerhetsanalys"
                      ? "activeSection col-12 px-8 fs-14 "
                      : "col-12 px-8 fs-14 "
                  }
                >
                  <h2>Säkerhetsanalys</h2>
                  <p className="fs-14 mb-8">
                    Vi erbjuder en rad olika säkerhetsanalyser utifrån era
                    behov.
                  </p>
                  <ul>
                    <li>
                      En risk- och sårbarhetsanalys hjälper er att bedöma och
                      hantera hotbilder, risker och sårbarheter. Analysen bidrar
                      till att skapa överblick, kontrull och
                      kostnadseffektivitet där varje risk bemöts med rätt
                      åtgärd.
                    </li>
                    <li>
                      Behöver ni förbättra säkerheten men vet inte var ni ska
                      börja? Är ni osäkra på om det är lås, larm eller
                      passersystem som behöver införskaffas/uppgraderas? En
                      behovsanalys kan ge er svaren. Den kostnad som en väl
                      utförd behovsanalys inledningsvis medför hämtas ofta igen
                      i nästa fas i säkerhetsarbetet. Vi hjälper er att sätta in
                      rätt säkerhetsåtgärd på rätt plats.
                    </li>
                    <li>
                      Har ditt företag varit utsatt för inbrott, skadegörelse,
                      obehöriga i lokalerna eller hot/våld mot personalen? En
                      skräddarsydd säkerhetsanalys kan hjälpa er att hantera
                      incidenter och klargöra vilka delar av säkerhetsarbetet
                      som behöver åtgärdas för att säkra upp er verksamhet.
                    </li>
                  </ul>
                  <Link
                    className="header-link color-link-black-underline"
                    to="/kontakta-oss"
                  >
                    <p>
                      Kontakta oss för ett fortsatt samtal kring
                      säkerhetsanalyser.
                    </p>
                  </Link>
                </div>
              </div>
            )}
            {selectedSection === "Utredning" && (
              <div className="relative">
                <div
                  className={
                    selectedSection === "Utredning"
                      ? "activeSection col-12 px-8 fs-14"
                      : "col-12 px-8 fs-14"
                  }
                >
                  <h2>Utredning</h2>
                  <p>
                    Har ditt företag koll på exakt vilka individer som har
                    tillträde till era lokaler? Är samtliga anställda medvetna
                    om vilka säkerhetsrutiner som gäller på arbetsplatsen? Är du
                    säker på att personalen alltid kan utrymma på ett säkert
                    sätt under verksamhetstid? Är du trygg i att era lokaler är
                    skyddade mot inbrott och skadegörelse eftersom den fysiska
                    och tekniska säkerheten är uppdaterad, välanpassad för era
                    lokaler och behov och dokumenterad på ett korrekt sätt? I
                    sådana fall säger vi bra jobbat!
                  </p>
                  <p>
                    Om ni däremot tillhör den stora skara företag som inte har
                    full kontroll på ert säkerhetsarbete är ni varmt välkomna
                    att kontakta oss. Vi utför säkerhetsutredningar av olika
                    slag som hjälper er att få kontroll över er verksamhet. Det
                    kan handla om att reglera tillträdet, optimera personflöden,
                    inventera och bedöma era säkerhetssystem eller att stötta i
                    era säkerhetsrutiner. Baserat på vad utredningen resulterar
                    i rekommenderar vi lämpliga åtgärder utifrån era
                    förutsättningar. En utredning är ofta det första steget mot
                    att förbättra säkerheten och tryggheten för både företaget
                    och dess personal.
                  </p>
                  <Link
                    className="header-link color-link-black-underline"
                    to="/kontakta-oss"
                  >
                    <p>
                      Kontakta oss för mer information om våra
                      säkerhetsutredningar.
                    </p>
                  </Link>
                </div>
              </div>
            )}
            {selectedSection === "Projektledning" && (
              <div className="relative">
                <div
                  className={
                    selectedSection === "Projektledning"
                      ? "activeSection col-12 px-8 fs-14"
                      : "col-12 px-8 fs-14"
                  }
                >
                  <h2>Projektledning</h2>
                  <p>
                    Som projektledare säkerställer vi att ert projekt utförs
                    fackmannamässigt och i enlighet med gällande lagar, avtal
                    och villkor. Ju tidigare vi är med desto bättre – och ofta,
                    i slutändan, billigare för er som beställare. Genom att
                    anlita oss redan i det inledande skedet får ni hjälp med att
                    skriva nulägesrapporter, kravspecifikationer,
                    upphandlingsunderlag och annat som föregår själva
                    projektledningsfasen. Ett gediget förarbete leder ofta till
                    stora ekonomiska besparingar och en mer behovsanpassad och
                    kvalitativ produkt. Vi är med och stöttar hela vägen.
                  </p>
                  <p>
                    Vi kan också utföra punktinsatser som projektledare. Till
                    exempel erbjuder vi behovsanalyser, anbudsutvärdering,
                    projektstyrning, kostnadsrådgivning, besiktning och
                    uppföljning.
                  </p>
                  <Link
                    className="header-link color-link-black-underline"
                    to="/kontakta-oss"
                  >
                    <p className="color-link-black">
                      Kontakta oss för ett samtal om hur vi kan bidra i just ert
                      säkerhetsprojekt.
                    </p>
                  </Link>
                </div>
              </div>
            )}
            {selectedSection === "Upphandling" && (
              <div className="relative">
                <div
                  className={
                    selectedSection === "Upphandling"
                      ? "activeSection col-12 px-8 fs-14"
                      : "col-12 px-8 fs-14"
                  }
                >
                  <h2>Upphandling</h2>
                  <p>
                    Att upphandla inbrottslarm, passersystem eller
                    trygghetskameror är inget lätt projekt. Det krävs mycket
                    kunskap för att utreda organisationens behov och klargöra
                    vilken typ av produkter som är bäst lämpade, hur
                    projektering och installation ska utföras och vilka
                    funktioner som behövs. Utan rätt kunskap kan ni tvingas
                    betala för en produkt som inte är anpassade efter er
                    verksamhet – något som går ut över både ekonomin och
                    säkerheten. Ett gediget och noggrant formulerat
                    förfrågningsunderlag är nyckeln till att få precis det ni
                    efterfrågar och samtidigt undvika oförutsedda kostnader.
                  </p>
                  <p>
                    Vi erbjuder stöd i samtliga skeden i upphandlingar inom
                    säkerhetsområdet, från första skissen till godkänd
                    slutbesiktning. Medan ni fokuserar på er verksamhet ser
                    Säkerhetskonsulterna till att ni får en produkt som är
                    skräddarsydd efter just era behov och att ni inte betalar en
                    krona mer än nödvändigt.
                  </p>
                  <p>
                    Vi har genomfört stora säkerhetsupphandlingar i
                    kontorskomplex, bostadsområden, museum, myndigheter och i
                    många andra miljöer. Det är med självklarhet och ett stort
                    intresse som vi följer säkerhetsteknikens utveckling och
                    håller oss uppdaterade kring relevanta regelverk och
                    leverantörer i branschen.
                  </p>
                  <p>
                    Målet med varje upphandling är att vår inblandning ska
                    betraktas som en besparing snarare än en kostnad eftersom
                    projektkostnaderna hålls nere i alla led. Det är inte
                    ovanligt att målet uppnås med flera miljoner kronors
                    marginal i större projekt, och detta samtidigt som
                    beställaren får en optimalt anpassad produkt levererad.
                  </p>
                  <Link
                    className="header-link color-link-black-underline"
                    to="/kontakta-oss"
                  >
                    <p className="color-link-black">
                      Kontakta oss för ett samtal kring hur vi kan hjälpa till
                      med er säkerhetsupphandling.
                    </p>
                  </Link>
                </div>
              </div>
            )}
            {selectedSection === "Säkerhetsstrategier" && (
              <div className="relative">
                <div
                  className={
                    selectedSection === "Säkerhetsstrategier"
                      ? "activeSection col-12 px-8 fs-14"
                      : "col-12 px-8 fs-14"
                  }
                >
                  <h2>Säkerhetsstrategier</h2>
                  <p>
                    <strong>Säkerhetsnivå:</strong> Alla verksamheter behöver
                    inte ha en säkerhet i nivå med Riksbanken, men alla kan inte
                    heller nöja sig med den mest grundläggande fysiska och
                    tekniska säkerheten. Med rätt säkerhetsnivå lägger ni
                    rimliga ekonomiska resurser på säkerhetsarbetet samtidigt
                    som åtgärderna i högre utsträckning kommer att accepteras
                    och efterlevas av personalen.
                  </p>
                  <p>
                    <strong>Användarvänlighet:</strong> Säkerhetsprodukter som
                    lås, inbrottslarm och passersystem måste vara
                    användarvänliga för att fungera i verksamheten.
                    Användarvänlighet kan uppnås på många sätt; produktdesign,
                    projektering, systemkonfiguration och styrning av
                    personflöden för att nämna några. Säkerhetskonsulterna
                    hjälper er att skapa användarvänlighet där det behövs för
                    just er.
                  </p>
                  <p>
                    <strong>Implementering:</strong> Oavsett säkerhetsnivå och
                    användarvänlighet behöver en säkerhetsåtgärd implementeras i
                    verksamheten för att efterlevas på bästa sätt. Vi erbjuder
                    ett skräddarsytt stöd i att implementera just era åtgärder,
                    exempelvis genom personalutbildningar, projektledning och
                    rådgivning.
                  </p>
                  <p>
                    <strong>Kostnadseffektivitet:</strong> Det billigaste är
                    inte alltid det bästa – men inte heller alltid det sämsta.
                    Med hjälp av till exempel behovsanalyser,
                    kostnadsuppskattningar, anbudsutvärderingar eller
                    leverantörskontakter hjälper vi er att ta fram den mest
                    kostnadseffektiva lösningen för er verksamhet.
                  </p>
                </div>
              </div>
            )}
            {selectedSection === "Inventering" && (
              <div className="relative">
                <div
                  className={
                    selectedSection === "Inventering"
                      ? "activeSection col-12 px-8 fs-14"
                      : "col-12 px-8 fs-14"
                  }
                >
                  <h2>Inventering</h2>
                  <p>
                    Är ni osäkra på vilka säkerhetssystem och komponenter som
                    faktiskt finns i verksamheten? I sådana fall är ni i gott
                    sällskap. Många företag har tyvärr inte full koll på vilka
                    lås som behöver bytas ut, vilka larmkomponenter som har
                    slutat fungera, vilka kameror som ger bevisdugligt material
                    och vilka kortläsare som står emot antagonisters nya
                    angreppsmetoder.
                  </p>
                  <p>
                    Vi utför inventeringar som ger er överblick över de
                    befintliga installationerna, vilket i sin tur skapar rätt
                    förutsättningar för eventuella uppgraderingar och utbyten.
                    Skjut inte problemet framför er och vänta på att
                    incidenterna ska inträffa – arbeta istället proaktivt, ta
                    kontroll över er säkerhet och erbjud säkerhet både för er
                    personal och era lokaler.
                  </p>
                  <Link
                    className="header-link color-link-black-underline"
                    to="/kontakta-oss"
                  >
                    <p className="color-link-black">
                      Kontakta oss för att höra mer om vad som ingår i en
                      inventering.
                    </p>
                  </Link>
                </div>
              </div>
            )}
            {selectedSection === "Granskning" && (
              <div className="relative">
                <div
                  className={
                    selectedSection === "Granskning"
                      ? "activeSection col-12 px-8 fs-14"
                      : "col-12 px-8 fs-14"
                  }
                >
                  <h2>Granskning</h2>
                  <p>
                    Alla typer av säkerhetshandlingar ska tåla en granskning.
                    Små misstag kan leda till stora konsekvenser, både för
                    säkerheten och resultaträkningen. Försäkringsbolag kan till
                    exempel neka ersättning till ett företag som drabbats av
                    inbrott om säkerhetshandlingarna är inkorrekta, även om
                    anläggarintyg har utfärdats och installationerna är i drift.
                    Genom att kontinuerligt låta en expert granska era
                    säkerhetshandlingar tar ni ansvar för att säkerhetsarbetet
                    håller måttet.
                  </p>
                  <p>
                    Vi granskar alla typer av dokumentation, bygghandlingar,
                    ritningsunderlag, offerter, avtal, utlåtanden,
                    upphandlingsunderlag – kort sagt det mesta som har med
                    fysisk och teknisk säkerhet att göra!
                  </p>
                  <Link
                    className="header-link color-link-black-underline"
                    to="/kontakta-oss"
                  >
                    <p className="color-link-black">
                      Kontakta oss för mer information om granskningar.
                    </p>
                  </Link>
                </div>
              </div>
            )}
            {selectedSection === "Dokumentation" && (
              <div className="relative">
                <div
                  className={
                    selectedSection === "Dokumentation"
                      ? "activeSection col-12 px-8 fs-14"
                      : "col-12 px-8 fs-14"
                  }
                >
                  <h2>Dokumentation</h2>
                  <p>
                    Det spelar ingen roll hur välfungerande er
                    säkerhetsanläggning är – är dokumentationen bristfällig
                    väntar problemen runt hörnet. Inte bara för att en korrekt
                    dokumentation är ett krav enligt gällande regelverk, utan
                    också för att servicearbeten och uppgraderingar ska kunna
                    utföras ordentligt. Vi har gång på gång sett hur en
                    bristfällig dokumentation har lett till både stora kostnader
                    och säkerhetsbrister, helt i onödan.
                  </p>
                  <p>
                    Vi kan hjälpa er med allt som har med säkerhetsdokumentation
                    att göra. Har ni en anläggning med brister i dokumentationen
                    kan vi hjälpa till med t.ex. installationsritningar,
                    förbindningsscheman och montageritningar. Ska ni genomföra
                    en upphandling kan vi ta fram en installationsritning för
                    att säkerställa att samtliga anbudsgivare räknar på samma
                    sak, vilket höjer kvaliteten i upphandlingen enormt mycket
                    och dessutom underlättar anbudsutvärderingen. Ska ni
                    genomföra en utförandeentreprenad kan vi hjälpa er med alla
                    underlag.
                  </p>
                  <p>
                    Vi är nitiska med dokumentationen och så ska det vara.
                    Arbetet bedrivs såväl i CAD som i andra gångbara program på
                    marknaden.
                  </p>
                  <Link
                    className="header-link color-link-black-underline"
                    to="/kontakta-oss"
                  >
                    <p className="color-link-black">
                      Kontakta oss för mer information om dokumentation.
                    </p>
                  </Link>
                </div>
              </div>
            )}
            {selectedSection === "Besiktning" && (
              <div className="relative">
                <div
                  className={
                    selectedSection === "Besiktning"
                      ? "activeSection col-12 px-8 fs-14"
                      : "col-12 px-8 fs-14"
                  }
                >
                  <h2>Besiktning</h2>
                  <p>
                    En besiktning som är utförd av neutral part är en
                    förutsättning för att ni ska kunna vara trygga med era
                    säkerhetsinstallationer. Det kan rentav vara nödvändigt för
                    att anläggningen ska vara godkänd och uppfylla era
                    kravställares villkor. Det kan också finnas krav på godkänd
                    slutbesiktning för att garantitiden ska börja gälla. Oavsett
                    kravbild är det ett kvitto för er som beställare på att det
                    ni har betalat för är korrekt och fackmannamässigt utfört.
                  </p>
                  <p>
                    Vi kan som <em>behörig ingenjör inbrottslarm</em> och{" "}
                    <em>behörig ingenjör CCTV</em> utföra denna typ av
                    besiktningar och därigenom säkerställa att er anläggning
                    uppfyller gällande regler och kvalitetskrav.
                  </p>
                  <Link
                    className="header-link color-link-black-underline"
                    to="/kontakta-oss"
                  >
                    <p className="color-link-black">
                      Kontakta oss för mer information om besiktningar!
                    </p>
                  </Link>
                </div>
              </div>
            )}
            {selectedSection === "Anbudsutvärdering" && (
              <div className="relative">
                <div
                  className={
                    selectedSection === "Anbudsutvärdering"
                      ? "activeSection col-12 px-8 fs-14"
                      : "col-12 px-8 fs-14"
                  }
                >
                  <h2>Anbudsutvärdering</h2>
                  <p>
                    Ett anbudsförfarande kan vara komplext och innehålla många
                    potentiella fallgropar för er som beställare. Det är inte
                    bara siffrorna på sista raden som har betydelse; minst lika
                    viktigt är det som står på (eller mellan) raderna ovanför.
                    Eller det som inte står. Anbudsgivare är inte alltid
                    transparenta med hur de planerar att leverera en produkt
                    eller tjänst och avtalsvillkoren kan vara svåra att utläsa.
                  </p>
                  <p>
                    Vi har lång erfarenhet av att både skriva och utvärdera
                    anbud och vet därför hur ett anbud ska granskas. Genom att
                    anlita oss för en anbudsutvärdering kvalitetssäkrar ni hela
                    kedjan anbud – leverans – betalning och minimerar samtidigt
                    risken för oförutsedda kostnader. Kort sagt – vi hjälper er
                    att få rätt produkt till rätt pris.
                  </p>
                  <Link
                    className="header-link color-link-black-underline"
                    to="/kontakta-oss"
                  >
                    <p className="color-link-black">
                      Kontakta oss för mer information om hur en
                      anbudsutvärdering kan gå till.
                    </p>
                  </Link>
                </div>
              </div>
            )}
            {selectedSection === "Second opinion" && (
              <div className="relative">
                <div
                  className={
                    selectedSection === "Second opinion"
                      ? "activeSection col-12 px-8 fs-14"
                      : "col-12 px-8 fs-14"
                  }
                >
                  <h2>Second opinion (andrahandsutlåtande)</h2>
                  <p>
                    Har ni fått en säkerhetstjänst eller produkt levererad men
                    är osäkra på om allt verkligen står rätt till? Ett anbud som
                    känns dyrt eller ofullständigt, ett utlåtande som verkar
                    inkorrekt, en handling eller riktlinje som lämnar fler
                    frågetecken än utropstecken – listan på exempel kan göras
                    lång. En olustig magkänsla kan avhjälpas genom att låta
                    Säkerhetskonsulterna ge en second opinion. Som extern part
                    granskar vi någon annans arbete och ger ett opartiskt
                    utlåtande. Det kan vara stora eller små jobb - inget är för
                    litet och det kan vara både billigt och snabbt utfört. Icke
                    desto mindre kan vårt utlåtande innebära stora ekonomiska
                    besparingar, eller åtminstone sinnesro och en trygghet i att
                    det jobb ni fått utfört är helt i sin ordning.
                  </p>
                  <Link
                    className="header-link color-link-black-underline"
                    to="/kontakta-oss"
                  >
                    <p className="color-link-black">
                      Kontakta oss för att prata vidare om andrahandsutlåtanden.
                    </p>
                  </Link>
                </div>
              </div>
            )}
            {selectedSection === "Kostnadsuppskattning" && (
              <div className="relative">
                <div
                  className={
                    selectedSection === "Kostnadsuppskattning"
                      ? "activeSection col-12 px-8 fs-14"
                      : "col-12 px-8 fs-14"
                  }
                >
                  <h2>Kostnadsuppskattning</h2>
                  <p>
                    Behöver ni genomföra en upphandling, ombyggnation eller ett
                    inköp av en produkt eller tjänst inom säkerhetsområdet men
                    är osäkra på vad som är en rimlig budget? Som opartisk och
                    erfaren säkerhetspartner hjälper vi er att göra en
                    kostnadsuppskattning som ger värdefullt stöd i både
                    investeringsbeslut och offertbedömning.
                  </p>
                  <Link
                    className="header-link color-link-black-underline"
                    to="/kontakta-oss"
                  >
                    <p className="color-link-black">
                      Kontakta oss för ett förutsättningslöst samtal kring era
                      säkerhetsinvesteringar.
                    </p>
                  </Link>
                </div>
              </div>
            )}
            {selectedSection === "Rådgivning" && (
              <div className="relative">
                <div
                  className={
                    selectedSection === "Rådgivning"
                      ? "activeSection col-12 px-8 fs-14"
                      : "col-12 px-8 fs-14"
                  }
                >
                  <h2>Rådgivning</h2>
                  <p>
                    Hittar du inte det du behöver hjälp med under våra tjänster?
                    Hör av dig ändå! Vi kan mer än vad vi skyltar med. Och om
                    inte just vi kan hjälpa dig så har vi ett stort kontaktnät
                    där vi sannolikt kan hitta den hjälp du behöver. Sätt oss på
                    prov, det kan utveckla både er säkerhet och vår verksamhet.
                    En säker win-win!
                  </p>
                  <p>
                    Vi delar gärna med oss av våra resurser för er säkerhets
                    skull.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
